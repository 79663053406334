<template>
  <div class="app-header">
    <div class="app-header-inner">
      <div class="logo">
        <nuxt-link class="home" title="パチンコ" to="/index.html"><img src="@/public/logo.png" class="logo-img" alt="パチンコ">パチンコワールド</nuxt-link>
      </div>
    </div>
  </div>
</template>

<style lang="less" scoped>
.app-header {
  background: #ffb60c;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: sticky;
  top: 0;
  left: 0;
  z-index: 10;
}
.app-header-inner {
  width: 90%;
}
.logo {
  padding: 10px 0;
  font-size: 20px;
  font-weight: bold;
  // 变瘦
  line-height: 1.5;
}
.home {
  color: #000;
  text-decoration: none;
}
.logo-img {
  width: 40px;
  height: 40px;
  margin-right: 5px;
  vertical-align: middle;
}
</style>
